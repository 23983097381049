@import-normalize;

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Sofia, 'SF Pro Display';
}

input {
  background-color: transparent;
}

a {
  text-decoration: unset;
  color: unset;
}

a:hover a:focus {
  text-decoration: unset;
  color: unset;
}
